@use "@/styles/abstracts" as *;

.link {
	color: $color-orange--primary;
}

.ul,
.ol {
	display: flex;
	flex-direction: column;
	padding-left: rem(20);

	li {
		margin: 0.1em 0;

		::marker {
			margin-right: 1em;
		}
	}
}
